import { FormDivInput } from '@playbooks/interface/forms';
import { InputGroup, InputPrepend } from '@playbooks/interface/input-groups';

const SearchDivForm = ({ prevIcon = 'magnifying-glass', children, tailwind }) => {
	// Render
	return (
		<InputGroup {...tailwind?.inputGroup}>
			<InputPrepend icon={prevIcon} {...tailwind?.inputPrepend} />
			<FormDivInput variant='group' spacing='py-2.5' {...tailwind?.input}>
				{children}
			</FormDivInput>
		</InputGroup>
	);
};

export { SearchDivForm };
