import { Fragment } from 'react';

import { Small } from '@playbooks/interface/fonts';
import { PagePagination } from 'molecules/paginations/page-pagination';
import { toNumber } from 'utils';

const BasicPagination = ({ meta, params, setParams }) => (
	<Fragment>
		<PagePagination meta={meta} params={params} setParams={setParams} />
		<Small>{toNumber(meta.totalRecords)} records</Small>
	</Fragment>
);

export { BasicPagination };
