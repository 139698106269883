import { Fragment } from 'react';

import { Small } from '@playbooks/interface/fonts';
import { Span } from '@playbooks/interface/html';
import { PagePagination } from 'molecules/paginations/page-pagination';
import { PageSizePagination } from 'molecules/paginations/page-size-pagination';
import { toNumber } from 'utils';

const TablePagination = ({ meta, params, setParams }) => (
	<Fragment>
		<Span display='flex-start' space='space-x-4'>
			<PagePagination meta={meta} params={params} setParams={setParams} />
			<Small>{toNumber(meta.totalRecords)} records</Small>
		</Span>
		<PageSizePagination meta={meta} params={params} setParams={setParams} />
	</Fragment>
);

export { TablePagination };
