import { BasicPagination, BtnPagination, SliderPagination, TablePagination } from 'molecules/paginations';

const Pagination = ({ type = 'basic', meta, params, setParams, taskRunning = false }) => {
	const props = { meta, params, setParams, taskRunning };
	// Render
	switch (type) {
		case 'basic':
			return <BasicPagination {...props} />;

		case 'btn':
			return <BtnPagination {...props} />;

		case 'slider':
			return <SliderPagination {...props} />;

		case 'table':
			return <TablePagination {...props} />;
	}
};

export { Pagination };
