import { Fragment } from 'react';

import { AccentBtn, BorderBtn, Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';

const ModalForm = ({ title, open, setOpen, taskRunning, onSave, children }) => {
	// Methods
	const onOpen = () => setOpen(true);

	const onClose = () => setOpen(false);

	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Fragment>
			<Modal open={open} onClose={onClose}>
				<Form onSubmit={onSubmit}>
					<ModalHeader onClose={onClose}>
						<ModalTitle>{title}</ModalTitle>
					</ModalHeader>
					<ModalBody>{children}</ModalBody>
					<ModalFooter>
						<AccentBtn onClick={onClose}>Close</AccentBtn>
						<Btn type='submit' taskRunning={taskRunning}>
							Save
						</Btn>
					</ModalFooter>
				</Form>
			</Modal>
			<BorderBtn size='icon' icon='plus' onClick={onOpen} />
		</Fragment>
	);
};

export { ModalForm };
