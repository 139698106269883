import { forwardRef, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { AccentBtn } from '@playbooks/interface/buttons';
import { FormInput } from '@playbooks/interface/forms';
import { InputAppend, InputGroup, InputPrepend } from '@playbooks/interface/input-groups';
import { timeout } from 'utils';

type iSearchForm = {
	id?: string;
	delay?: number;
	length?: number;
	prevIcon?: string;
	placeholder?: string;
	loading?: boolean;
	query: string;
	setQuery: any;
	hasBlur?: boolean;
	onFocus?: () => any;
	onClear?: () => any;
	elements?: any;
	tailwind?: any;
};

const SearchForm = forwardRef(
	(
		{
			id,
			delay = 300,
			length = 3,
			prevIcon = 'magnifying-glass',
			placeholder,
			query,
			setQuery,
			loading,
			hasBlur,
			onFocus,
			onClear,
			elements,
			tailwind,
		}: iSearchForm,
		ref,
	) => {
		const [localQuery, setLocalQuery] = useState(query || '');
		const [queue, setQueue] = useState([]);
		const [loaded, setLoaded] = useState(false);
		const router = useRouter();

		// Hooks
		useEffect(() => {
			if (router.isReady) setLocalQuery(query);
			setLoaded(true);
		}, [router.isReady]);

		useEffect(() => {
			if (loaded) addQuery();
		}, [localQuery]);

		useEffect(() => {
			if (queue.length > 0) processQuery();
		}, [queue]);

		useEffect(() => {
			window.addEventListener('keydown', onKeyDown);
			return () => window.removeEventListener('keydown', onKeyDown);
		}, []);

		// Methods
		const onBlur = () => {
			if (hasBlur) setLocalQuery('');
		};

		const onKeyDown = e => {
			if (e.keyCode === 27) clearSearch();
		};

		const addQuery = async () => {
			await timeout(delay);
			if (localQuery.length === 0) clearSearch();
			if (localQuery.length >= length && !queue.includes(localQuery)) setQueue([...queue, localQuery]);
		};

		const processQuery = () => {
			const nextQuery = queue[0];
			if (queue.length > 0) {
				if (localQuery === nextQuery) setQuery(nextQuery);
				setQueue(queue.filter(v => v !== nextQuery));
			}
		};

		const clearSearch = () => {
			setQuery('');
			setLocalQuery('');
			setQueue([]);
			if (onClear) onClear();
			const input = document.getElementById(id) as HTMLInputElement;
			if (input) input.value = '';
		};

		// Render
		return (
			<InputGroup overflow='overflow-hidden' {...tailwind?.inputGroup}>
				<InputPrepend icon={prevIcon} {...tailwind?.inputPrepend} />
				<FormInput
					id={id}
					ref={ref}
					value={localQuery}
					variant='group'
					placeholder={placeholder}
					onBlur={onBlur}
					onChange={e => setLocalQuery(e.target.value)}
					onFocus={onFocus ? onFocus : null}
					spacing='py-3.5 px-0'
					{...tailwind?.input}
				/>
				{elements?.inputAppend ? (
					elements?.inputAppend
				) : (
					<InputAppend spacing='pl-0' {...tailwind?.inputAppend}>
						{query.length >= length && (
							<AccentBtn size='icon' icon='xmark' taskRunning={loading} onClick={clearSearch} />
						)}
					</InputAppend>
				)}
			</InputGroup>
		);
	},
);

export { SearchForm };
